<script>
    const roles = ["Developer", "Admin", "Moderator", "Helper", "Translator"];
    let team = [];

    let name = "";

    fetch("/.netlify/functions/team")
    .then(res => res.json())
    .then(data => {
        team = data;
    });
</script>

<div class="container text-center mt-5">
    <h1 class="display-4">
        Team
    </h1>

    <div class="mt-2 mb-3 d-flex justify-content-center flex-column">
        {#each roles as role}
            <div class="mt-3">
                <h3>
                    {role}
                </h3>
                <div class="box d-flex flex-row flex-wrap">
                    {#each team.filter(i => i.role == role) as user}
                        <div class="user">
                            <img width=180 src="{user.memberAvatar || user.userAvatar}?size=512" alt="{user.nickname || user.username}'s Avatar">
                            <h5 class="font-weight-bold">{@html user.nickname ? user.nickname.match(/.{1,12}/g).join("<br/>") : user.username.match(/.{1,12}/g).join("<br/>")}</h5>
                        </div>
                    {/each}
                </div>
            </div>
        {/each}
    </div>
</div>

<style>
    .user {
        margin: 15px;
    }

    .user img {
        border: 3px solid #83baff;
        border-radius: 50%;
    }
    
    .box {
        justify-content: space-evenly;
    }
</style>