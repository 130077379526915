<script>
    const commandsList = [{"name":"8ball","group":"extra","description":"Ask the magic 8ball a question!"},{"name":"kitsu","group":"extra","description":"Search for an anime using Kitsu!"},{"name":"add","group":"main","description":"Add an anime to your list!"},{"name":"edit","group":"main","description":"Edit an anime in your list!"},{"name":"list","group":"main","description":"View your anime list!"},{"name":"profile","group":"main","description":"View your anime profile!"},{"name":"top","group":"main","description":"View the anime with the most users!"},{"name":"anime","group":"search","description":"Search for an anime using AniBot!"},{"name":"genres","group":"search","description":"View all the anime genres that this bot supports!"},{"name":"image-search","group":"main","description":"Search for an anime using a screenshot!"},{"name":"airing","group":"tracking","description":"View the anime airing schedule for the week!"},{"name":"news","group":"tracking","description":"Configure news feed updates in your server!"},{"name":"server-tracking","group":"tracking","description":"Configure anime airing alerts for your server!"},{"name":"track","group":"tracking","description":"Configure anime airing alerts for your DMs!"},{"name":"trackable","group":"tracking","description":"View the anime that can be tracked!"},{"name":"help","group":"util","description":"View the bots help page!"},{"name":"import","group":"util","description":"Import your anime list from MAL!"},{"name":"info","group":"util","description":"View basic bot information!"},{"name":"invite","group":"util","description":"View both the bot and support server invites!"},{"name":"ping","group":"util","description":"Pong!"},{"name":"prefix","group":"util","description":"Configure the bot prefix for this server!"}];
    let commands = commandsList;

    function filter() {
        commands = this.id == "all" ? commandsList : commandsList.filter(i => i.group == this.id);
    }

    function search() {
        commands = [];
        for (let i of commandsList) {
            if (fuzzyMatch(this.value, i.name) || fuzzyMatch(this.value, i.description)) commands.push(i)
        }
    }

    function fuzzyMatch(pattern, str) {
        pattern = ".*" + pattern.split("").join(".*") + ".*";
        const re = new RegExp(pattern, "i");
        return re.test(str);
    }
</script>

<div class="container text-center mt-5">
    <h1 class="display-4">
        Commands
    </h1>

    <div class="mt-3 d-flex align-items-center flex-column">
        <div class="groups d-flex flex-row flex-wrap justify-content-center">
            <button id="main" type="button" class="btn btn-outline-primary" on:click={filter}>Main</button>
            <button id="search" type="button" class="btn btn-outline-secondary" on:click={filter}>Search</button>
            <button id="tracking" type="button" class="btn btn-outline-success" on:click={filter}>Tracking</button>
            <button id="util" type="button" class="btn btn-outline-danger" on:click={filter}>Util</button>
            <button id="extra" type="button" class="btn btn-outline-warning" on:click={filter}>Extra</button>
            <button id="all" type="button" class="btn btn-outline-info" on:click={filter}>All</button>
        </div>

        <div class="input-group mb-3 mt-3 w-50">
            <input type="text" class="form-control" aria-label="Default" aria-describedby="Search" on:input={search}>
        </div>
    </div>

    <div class="mt-3 mb-3 d-flex flex-row justify-content-center flex-wrap">
        {#each commands as i}
            <div class="w-50">
                <div class="box">
                    <div class="name p-2 d-flex flex-row flex-wrap">
                        <div class="text-left">
                            {i.name}
                        </div>
                        <div class="text-right">
                            {i.group}
                        </div>
                    </div>
                    <div class="description p-2">
                        {i.description}
                    </div>
                </div>
            </div>
        {/each}
    </div>
</div>

<style>
    .box {
        margin: 15px;
    }

    .box .name {
        font-size: 25px;
        background-color: rgba(255, 243, 228, 0.4);
        background-color: rgba(0, 0, 0, 0.03);
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;

        justify-content: space-between;
    }

    .groups button {
        margin: 5px;
    }

    /* .input-group input {
        background-color: transparent !important;
        border: none !important;

        border: 5px solid #83baff !important;
        border-radius: 25px;

        background-color: rgba(255, 243, 228, 0.4) !important;
        background-color: rgba(0, 0, 0, 0.03) !important;
    } */
</style>