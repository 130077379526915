<script>
    const data = [
        {
            "question": "What are the features that this bot offers?",
            "answer": `The core purpose of this bot is to create anime watch lists on discord.<br>
                Additionally, it also has some nifty extra features, for example, anime-related feeds, and episode airing alerts.`
        },
        {
            "question": "How do I add anime to my list?",
            "answer": `To add anime to your list use the: <kbd>ab!add</kbd> command!<br>
                For example, to add Naruto the command would be: <kbd>ab!add naruto</kbd>.`
        },
        {
            "question": "Why is the bot not responding?",
            "answer": "Please make sure you're using the correct prefix, the bot is online, and has the correct permissions."
        },
        {
            "question": "How do I set up a news feed?",
            "answer": `Setting up a news feed is extremely simple!<br><br>
                First we add a channel using: <kbd>ab!news -add #channel</kbd><br>
                Once done, we will have to activate the feeds we want for that channel!<br>
                We can get a list of valid feeds using: <kbd>ab!news feeds</kbd><br>
                Now using: <kb>ab!news 1 -toggle ann</kb> we can toggle feeds for that channel!<br>
                This will toggle the ANN feed for feed channel 1!`
        },
        {
            "question": "Where can I get help with the bot?",
            "answer": "In the <a href='https://realm.anibot.moe/' target='_blank'><u>Support Server!</u></a>"
        }
    ]
</script>

<div class="container text-center mt-5">
    <h1 class="display-4">
        Frequently Asked Questions (FAQ)
    </h1>

    <div class="mt-3 mb-3 d-flex flex-column align-items-center">
        <div id="accordion" class="w-50">
            {#each data as i, a}
                <div class="card">
                    <div class="card-header" id="heading{a}">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse{a}" aria-expanded="true" aria-controls="collapse{a}">
                            {@html i.question}
                        </button>
                    </h5>
                    </div>
                
                    <div id="collapse{a}" class="collapse" aria-labelledby="heading{a}" data-parent="#accordion">
                        <div class="card-body">
                            {@html i.answer}
                        </div>
                    </div>
                </div>
            {/each}
        </div>
    </div>
</div>

<style>
    .card {
        border: 3px solid #83baff;
        border-radius: 25px;
        background-color: rgba(255, 243, 228, 0.4);
        margin-top: 10px;
    }

    .card-header {
        border: none;
        border-radius: 25px;
    }

    .card-header button {
        font-size: 20px;
		color: inherit;
	}

	.card-header button:hover {
		color: #83baff;
	}
</style>