<script>
</script>

<footer>
	<div class="mt-5 h-100 d-flex justify-content-center align-items-center">
		<a href="/privacy.html" target="_blank">Privacy Policy</a>
	</div>
</footer>

<style>
    footer {
		height: 60px;
		background-color: rgba(255, 243, 228, 0.3);
		font-size: 20px;
	}

	footer a:hover {
		color: #83baff;
	}
</style>