<script>

</script>

<div class="container text-center mt-5">
    <div class="d-flex flex-column align-items-center justify-content-center">
        <h1 class="display-2">AniBot</h1>
        <p class="h3">
            Create your very own Anime List on Discord.<br />
            Add and rate anime, track the ones airing, receive anime news updates,
            and much, much more.
        </p>
    </div>

    <div class="mt-3 d-flex justify-content-center flex-row flex-wrap">
        <button class="button add-btn m-1">
            <i style="font-size: 25px;" class="fa-brands fa-discord"></i>
            <a style="-webkit-text-stroke: 0;" href="/invite.html" target="_blank">Add AniBot!</a>
        </button>

        <button class="button srv-btn m-1">
            <i style="font-size: 25px;" class="fa-solid fa-link"></i>
            <a style="-webkit-text-stroke: 0;" href="https://realm.anibot.moe/" target="_blank">Join Support Server!</a>
        </button>
    </div>

    <div class="mt-5">
        <h1 class="display-4">Features</h1>
        <div class="features">
            <p>Anime News Updates</p>
            <p>Anime Airing Alerts</p>
            <p>Create your own Anime List</p>
            <p>And much, much more...</p>
        </div>
    </div>
</div>

<style>
    .features {
        font-size: 30px;
    }
</style>