<script>
	import Footer from "./components/footer.svelte";

	import Index from "./pages/index.svelte";
	import Cmds from "./pages/cmds.svelte";
	import Faq from "./pages/faq.svelte";
	import Team from "./pages/team.svelte";

	const pathname = window.location.pathname.replace("/", "");
	const href = "javascript:void(0);"

	let page = pathname;
	let loading;

	function changePage() {
		if (this.id == page) return;
		loading.style.visibility = "visible";

		window.history.replaceState({}, "", `/${this.id == "index" ? "" : this.id}`);

		setTimeout(() => {
			page = this.id
			setTimeout(() => loading.style.visibility = "hidden", 800);
		}, 800);
	}
</script>

<nav class="navbar navbar-expand-lg">
	<div>
		<button
			class="navbar-toggler"
			type="button"
			data-toggle="collapse"
			data-target="#navbar">
			<i class="fa-solid fa-bars" style="font-size: 35px; color: #83baff;"></i>
		</button>
	</div>
	<div class="collapse navbar-collapse" id="navbar">
		<div class="w-100 navbar-nav justify-content-center">
			<a id="index" class="nav-item nav-link {page == "index" ? "disabled": ""}" href={href} on:click={changePage}>
				<i class="fa-solid fa-house"></i>
				Home
			</a>
			<a id="cmds" class="nav-item nav-link {page == "cmds" ? "disabled": ""}" href={href} on:click={changePage}>
				<i class="fa-solid fa-terminal"></i>
				Commands
			</a>
			<a id="faq" class="nav-item nav-link {page == "faq" ? "disabled": ""}" href={href} on:click={changePage}>
				<i class="fa-regular fa-circle-question"></i>
				FAQ
			</a>
			<a id="team" class="nav-item nav-link {page == "team" ? "disabled": ""}" href={href} on:click={changePage}>
				<i class="fa-solid fa-users"></i>
				Team
			</a>
		</div>
	</div>
</nav>

<div bind:this={loading} style="visibility: hidden;" id="loading" class="d-flex justify-content-center align-items-center">
	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</div>

{#if page == "index"}
	<Index></Index>
{:else if page == "cmds"}
	<Cmds></Cmds>
{:else if page == "faq"}
	<Faq></Faq>
{:else if page == "team"}
	<Team></Team>
{:else}
	{page = "index"}
{/if}

<Footer></Footer>

<style>
	.navbar-nav a:hover {
		color: #83baff;
	}

	.disabled {
		color: #6c757d !important;
		cursor: default;
	}
</style>
